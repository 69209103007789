import React from "react"
import PropTypes from "prop-types"
//utilities
import styled from "styled-components"
import { rhythm } from "../utils/typography"
import { themeMedia } from "../utils/theme"
//components
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Tag from "../components/Tag"
import IndexLayout from "../components/IndexLayout"

/**
 * Styled components for all tags page
 */
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  //grid-auto-rows: auto;
  grid-auto-flow: row dense;
  grid-gap: ${rhythm(1)};
  align-items: center;

  @media (max-width: ${themeMedia.maxMedium}) {
    grid-template-columns: repeat(3, auto);
  }
  @media (max-width: ${themeMedia.maxSmall}) {
    grid-template-columns: repeat(2, auto);
  }
`

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
  location,
}) => (
  <>
    <Helmet title={title} />
    <IndexLayout location={location}>
      <h1>Liste des catégories</h1>
      <GridContainer>
        {group.map(tag => (
          <div key={tag.fieldValue}>
            <Tag tag={tag.fieldValue} />({tag.totalCount})
          </div>
        ))}
      </GridContainer>
    </IndexLayout>
  </>
)

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
